import React from "react"
import { graphql, Link } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import modelvideo from "../images/3Series-Touring.mp4"
import icon1 from "../images/icon-drop.png"
import icon2 from "../images/icon-streering.png"
import icon3 from "../images/icon-fix.png"
import icon4 from "../images/icon-user.png"

const BMW_i4 = ({ data, location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav urlParam={url_param}/>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36 md-pb-0">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
            <div className="d-none d-md-block">
                <h2>{data.allMdx.edges[0].node.frontmatter.sub_masthead_line1}</h2>
                <h2>{data.allMdx.edges[0].node.frontmatter.sub_masthead_line2}</h2>
            </div>
            <div className="d-block d-md-none">
                <h2>{data.allMdx.edges[0].node.frontmatter.sub_masthead_line1_mobile}</h2>
                <h2>{data.allMdx.edges[0].node.frontmatter.sub_masthead_line2_mobile}</h2>
                <h2>{data.allMdx.edges[0].node.frontmatter.sub_masthead_line3_mobile}</h2>
            </div>
          </div>
        </div>
      </div>
      <section className="section sectionFirst">
        <div className="container pt-5">
          {/* <h1 className="text-xl md:text-3xl xl:text-4xl">Versatility at its finest.</h1> */}
          {/* <h2
            id="featured-models"
            className="text-2xl lg:text-3xl xl:text-3xl uppercase font-light"
          >
            A Dream Come True.
          </h2> */}
          {/* <span class="border-t border-neutral-400 block w-full my-3"></span> */}
          <p className="mt-1">The all-electric BMW i4 delivers outstanding dynamics with a high level of comfort and the ideal qualities to make it your daily driver. The five-door model comes equipped with fifth-generation BMW eDrive technology and also the BMW Live Cockpit with Curved Display. It is your perfect companion for any journey.</p>
          
          
        </div>
      </section>
      {/* <section className="section">
        <div className="container">
          <video width="100%" loop muted autoPlay playsInline controls>
            <source src={modelvideo}  />
          </video>
        </div>
    </section> */}
      <section className="section">
        <div className="container">
          <iframe
            className="w-full h-[1700px] md:h-[1500px]"
            src="https://cloud.mail.ea-bmw.com.sg/Register_Interest"
            border="0"
            frameborder="0" title="Register Your Interest"
          ></iframe>
        </div>
      </section>
      {/* <div className='container column-4-icon my-5'>
      <div className="row">
        <div className="col-12 col-md-3 box">
          <Link
            to={"/register/" + url_param}
            data-gtm-category="THE 5"
            data-gtm-action="Clicked_Btn"
            data-gtm-label="Register Now"
          >
          <img src={icon1}></img>
          <p>Locate a Dealer</p>
        </Link>
        </div>
        <div className="col-12 col-md-3 box">
          <Link
              to={"/test-drive/" + url_param}
              data-gtm-category="THE 5"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Register Now"
            >
            <img src={icon2}></img>
            <p>Book a Test Drive</p>
          </Link>
        </div>
        <div className="col-12 col-md-3 box">
          <Link
              to={"/register/" + url_param}
              data-gtm-category="THE 5"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Register Now"
          >
            <img src={icon3}></img>
            <p>Book a Service</p>
          </Link>
        </div>
        <div className="col-12 col-md-3 box">
          <Link
            to={"/register/" + url_param}
            data-gtm-category="THE 5"
            data-gtm-action="Clicked_Btn"
            data-gtm-label="Register Now"
          >
            <img src={icon4}></img>
            <p>Contact Dealer</p>
          </Link>
        </div>
      </div>
    </div> */}
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "register-your-interest-i4" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            sub_masthead_line1
            sub_masthead_line2
            sub_masthead_line1_mobile
            sub_masthead_line2_mobile
            sub_masthead_line3_mobile
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default BMW_i4

const seoDetails = {
  title: "Eurokars Auto | BMW i4 Register Your Interest",
  description: "First-ever Full Electric Gran Coupe.",
  keywords: "BMW i4",
  image: "og-eka-contact-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
